@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100..900&display=swap');
@import '@fontsource/firago/400.css';
@import '@fontsource/firago/500.css';
@import '@fontsource/firago/600.css';
@import '@fontsource/firago/700.css';
@import '@fontsource/firago/500-italic.css';

:root {
  --color-white: #FFFFFF;
  --color-black: #212121;
  --modal-bg: rgb(33 33 33 / 60%);
  --color-primary: #333e74;
  --color-primary-dark: #001833;
  --color-primary-light: #99cccc;
  --color-secondary: #c1a456;
  --color-red: #9b2d2e;
  --color-bgblue-dark: #1a2334;
  --color-bgblue-light: #212a3b;
  --color-light: #EEEEEE;
  --color-more-light: #f7f7f7;
  --color-grey: #6e6e6e;
  --color-grey-light: #a3a3a3;
  --color-gold-gradient: linear-gradient(90deg, #c5a14b 0%, #f6f5c5 100%);
}

* {
  font-family: 'FiraGO', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

body {
  font-family: 'FiraGO', sans-serif;
}

a {
  text-decoration: none;
}
/* cusatom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: var(--color-light); 
}
::-webkit-scrollbar-thumb {
  background: var(--color-grey-light); 
}
::-webkit-scrollbar-thumb:hover {
  background: var(--color-grey); 
}

/* Footer style */
.footer {
  position: relative;
  background-color: var(--color-primary-dark);
  padding-top: 16px;
  z-index: 11;
}
.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../image/footerbg.png);
  background-size: cover;
  background-repeat: no-repeat
}
.footerMiddleBox {
  position: relative;
  width: 100%;
}
.footerLogo {
  height: 130px;
  width: 130px;
  margin: 0 auto;
  background: var(--color-white);
  border-radius: 50%;
  padding: 8px;
  margin-top: -110px;
  transition: 0.3s ease;
}
.footerLogo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.footerLogo:hover {
  transition: 0.3s ease;
  transform: scale(1.05);
}
.footerBottom {
  position: relative;
  padding: 40px 0px;
  border-top: 1px solid var(--color-grey);
}
.footerBottomRights {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  margin-top: 16px;
}
.footerBottomRights p {
  margin-bottom: 0px;
  color: var(--color-grey-light);
  font-size: 14px;
  font-feature-settings: 'case';
}
.footerBottomSocial {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.footerBottomLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}
.soacialMedia a {
  color: var(--color-light);
  font-size: 22px;
  transition: 0.3s ease;
}
.soacialMedia a:hover {
  color: var(--color-secondary);
  transition: 0.3s ease;
}
.soacialMedia {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.linksMedia a {
  color: var(--color-light);
  font-size: 22px;
  transition: 0.3s ease;
}
.linksMedia a:hover {
  color: var(--color-secondary);
  transition: 0.3s ease;
}
.linksMedia {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.singleSocial {
  height: 40px;
  width: 40px;
  border: 1px solid var(--color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 10px;
}
.singleSocial svg {
  font-size: 1rem;
}
.singleLinks {
  height: 40px;
  width: 40px;
  border: 1px solid var(--color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 10px;
}
.singleLinks svg {
  font-size: 1rem;
}
.scrollToTop {
  position: fixed;
  bottom: 30px;
  right: 40px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: var(--color-primary);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.scrollToTop svg {
  font-size: 18px;
  color: var(--color-white);
}
.bounceArrow {
  display: inline-block;
  transition: transform 0.3s ease;
}
.scrollToTop:hover .bounceArrow {
  animation: bounce 0.4s infinite alternate;
}
@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}
.footerTop {
  position: relative;
  padding-bottom: 120px;
}
.footerWidgetBox {
  position: relative;
}
.footerWidgetBoxTitle {
  position: relative;
  color: var(--color-light);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 32px;
  font-feature-settings: 'case';
}
.footerWidgetBoxTitle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 28px;
  height: 3px;
  width: 80px;
  background-color: var(--color-primary);
}
.footerWidgetBoxTitle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 28px;
  height: 3px;
  width: 16px;
  background-color: var(--color-secondary);
}
.footerWidgetBox ul {
  margin: 0;
  padding: 0;
}
.footerWidgetBox ul li {
  position: relative;
  list-style: none;
  padding-left: 16px;
  margin-bottom: 16px;
  transition: 0.3s ease;
}
.footerWidgetBox ul li svg {
  color: var(--color-light);
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 10px;
  font-size: 8px;
  transition: 0.3s ease;
}
.footerWidgetBox ul li:hover {
  padding-left: 20px;
  transition: 0.3s ease;
}
.footerWidgetBox ul li:hover a, .footerWidgetBox ul li:hover svg {
  color: var(--color-white);
  transition: 0.3s ease;
}
.footerWidgetBox ul li a {
  text-decoration: none;
  color: var(--color-grey-light);
  font-size: 14px;
  transition: 0.3s ease;
}
.footerContact {
  padding-left: 24px !important;
  transition: 0.3s ease;
}
.footerContact:hover {
  padding-left: 28px !important;
  transition: 0.3s ease;
}
.footerContactIcon {
  color: var(--color-light) !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  margin-top: 6px !important;
  font-size: 14px !important;
  transition: 0.3s ease !important;
}
.footerSubscribeBox {
  transform: translateY(-70px);
  padding: 40px;
  background: var(--color-primary-light);
  border-radius: 24px;
  outline: 10px solid var(--color-white);
}
.newsletterText {
  position: relative;
}
.newsletterText h4 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 16px;
  color: var(--color-primary-dark);
  font-feature-settings: 'case';
}
.newsletterText p {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0px;
  color: var(--color-primary-dark);
}
.subscribeInputBox {
  position: relative;
}
.subscribeInputBox input {
  background: var(--color-white);
  border: 0;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  padding: 10px 20px;
  padding-right: 140px;
}
.subscribeInputBox input:focus-visible {
  outline: none;
}
.subscribeButton {
  position: absolute;
  top: 0;
  right: 0;
  height: 52px;
  margin-top: 4px;
  margin-right: 4px;
  border-radius: 30px;
  padding: 10px 24px;
  background: var(--color-primary-light);
  border: 2px solid var(--color-primary-light);
  font-weight: bold;
  color: var(--color-white);
  transition: 0.3s ease;
  font-feature-settings: 'case';
}
.subscribeButton:hover {
  background: var(--color-primary);
  color: var(--color-white);
  border: 2px solid var(--color-primary);
  transition: 0.3s ease;
}

/* Header style */
header {
  position: relative;
  z-index: 12;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 10px 60px;
  z-index: 10;
  background-color: rgb(255 255 255 / 85%);
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}
.header.sticky {
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px 60px;
  background: rgb(255 255 255 / 70%);
  backdrop-filter: blur(20px);
  transition: all 0.3s ease;
}
.mainlogo {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
}
.mainlogo img {
  height: 60px;
  width: auto;
}
/* navigation */
.menu {
  width: 100%;
  height: 100%;
}
.menu .navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding: 0;
  height: 100%;
  width: 100%;
}
.menu .menu-toggle {
  display: block;
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
}

/* Styles for navigation links */
.menu .nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.menu .nav-links li {
  margin-left: 40px;
  transition: 0.3s ease;
}
.mrgLft0 {
  margin-left: 0px;
}
.menu .nav-links a:hover {
  color: var(--color-secondary);
  transition: 0.3s ease;
}
.menu .nav-links li:hover .dropdown-toggle {
  color: var(--color-secondary);
  transition: 0.3s ease;
}
.menu .nav-links a {
  text-decoration: none;
  color: var(--color-black);
  font-weight: 500;
  padding: 30px 0px;
  font-size: 16px;
  transition: 0.3s ease;
  font-feature-settings: 'case';
}

/* Dropdown styles */
.menu .dropdown {
  position: relative;
}

.menu .dropdown-menu {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 350px;
  max-width: 350px;
  background-color: var(--color-white);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 10px;
  margin-top: 20px;
  border-top: 3px solid var(--color-primary-light);
  transition: 0.3s ease;
}

.menu .dropdown-menu li {
  position: relative;
  padding: 10px;
  margin-left: 16px;
  transition: 0.3s ease;
}
.menu .dropdown-menu li:hover {
  transition: 0.3s;
  position: relative;
}
.menu .dropdown-menu li::before {
  position: absolute;
  top: 22px;
  left: 0;
  content: "";
  height: 1px;
  width: 0px;
  background-color: var(--color-primary-light);
  display: inline-block;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden;
}
.menu .dropdown-menu li:hover::before {
  width: 20px;
  visibility: visible;
  opacity: 1;
}
.menu .dropdown-menu li:hover {
  padding-left: 24px;
  transition: 0.3s ease;
}

.menu .dropdown-menu a {
  text-decoration: none;
  color: var(--color-primary-dark);
  padding: 0;
  transition: 0.3s ease;
}
.menu .dropdown-menu li:hover a {
  color: var(--color-primary-light);
  transition: 0.3s ease;
}

.menu .dropdown:hover .dropdown-menu {
  visibility: visible;
  transition: 0.3s ease;
}
.menu .dropdown-toggle::after {
  display: none;
}
.menuArrow {
  transition-duration: 0.3s;
  margin-top: -2px;
  position: absolute;
  right: 0;
  margin-right: -15px;
}

.rotate-up {
  transform: rotate(360deg);
  transition-duration: 0.3s;
}

.rotate-down {
  transform: rotate(0deg);
  transition-duration: 0.3s;
}
.menu .menuArrow svg {
  font-size: 10px;
}
.menu .nav-links a:hover .menuArrow {
  color: var(--color-secondary);
}
/* Responsive menu */
@media (max-width: 768px) {
  .menu .menu-toggle {
    display: block;
  }

  .menu .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #f8f9fa;
  }

  .menu .nav-links.open {
    display: flex;
  }

  .menu .nav-links li {
    margin: 0;
  }
}
.headerTop {
  background: var(--color-bgblue-light);
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 12;
  position: relative;
}
.headerTopInner {
  padding: 8px 60px;
  color: var(--color-white);
}
.headerTop .container-fluid {
  padding: 0;
}
.searchIcon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 32px;
}
.searchIcon svg {
  color: var(--color-black);
  font-size: 18px;
}
.searchIcon:hover svg {
  color: var(--color-white);
}
.searchBox {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--modal-bg);
  backdrop-filter: blur(5px);
  transform: translate(0);
  transition-duration: 0.3s;
}
.searchBoxInner {
  width: 600px;
  background: var(--color-white);
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.searchBoxInnerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--color-light);
}
.searchBoxInnerHeader h3 {
  position: relative;
  color: var(--color-black);
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0px;
}
.closeSearchBox {
  height: 40px;
  width: 40px;
  background: var(--color-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.closeSearchBox svg {
  font-size: 1.2rem;
}
.searchBoxInnerBody {
  height: calc(100% - 80px);
  padding: 20px;
  overflow-y: auto;
}
.searchInputBox {
  position: relative;
  padding: 8px 12px 8px 12px;
  background: var(--color-light);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.searchInput {
  width: 100%;
  background: transparent !important;
  color: var(--color-primary-dark);
  font-size: 18px;
  border: none !important;
  border-radius: 3px;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
}
.searchInput:focus-visible {
  outline: 0;
}
.searchButton {
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 17px;
  font-weight: normal;
  width: fit-content;
  background: var(--color-primary);
  color: var(--color-white);
  border: none;
  margin-left: 12px;
}
.searchInputResults {
  margin-top: 20px;
}
.searchInputSingleResults {
  margin-bottom: 12px;
}
.searchInputSingleResults h4 {
  font-size: 18px;
  margin-bottom: 12px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
  color: var(--color-grey);
}
.searchInputSingleResults h4 span {
  color: var(--color-primary);
  margin-left: 12px;
  font-size: 16px;
  float: right;
}
.searchInputSingleResults ul {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  padding-left: 20px;
}
.searchInputSingleResults ul li {
  list-style: none;
  position: relative;
  margin-bottom: 10px;
}
.searchInputSingleResults ul li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-secondary);
  height: 4px;
  width: 4px;
  margin-top: 10px;
  margin-left: -12px;
}
.searchInputSingleResults ul a {
  text-decoration: none;
  color: var(--color-primary-dark);
  font-size: 15px;
  transition-duration: 0.3s;
}
.searchInputSingleResults ul a:hover {
  color: var(--color-secondary);
  transition-duration: 0.3s;
}
.headerTopInnerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.headerTopAddress svg {
  font-size: 14px;
  color: var(--color-primary-light);
}
.headerTopAddress a {
  color: var(--color-light);
  margin-left: 10px;
  font-size: 14px;
  text-decoration: none;
  transition-duration: 0.3s;
}
.headerTopAddress a:hover {
  color: var(--color-white);
  transition-duration: 0.3s;
}
.headerTopPhone {
  margin-left: 20px;
}
.headerTopPhone svg {
  font-size: 14px;
  color: var(--color-primary-light);
}
.headerTopPhone a {
  color: var(--color-light);
  margin-left: 10px;
  font-size: 14px;
  text-decoration: none;
  transition-duration: 0.3s;
}
.headerTopPhone a:hover {
  color: var(--color-white);
  transition-duration: 0.3s;
}
.headerTopInnerRight {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.headerTopLanguageBox {
  position: relative;
  padding-right: 24px;
}
.headerTopLanguageBox a {
  font-size: 15px;
  text-decoration: none;
  color: var(--color-white);
  margin-left: 16px;
  transition-duration: 0.3s;
}
.headerTopLanguageBox a:hover {
  color: var(--color-primary-light);
  transition-duration: 0.3s;
}
.headerTopLanguageBox::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background: var(--color-grey-light);
  height: 20px;
  width: 1px;
  margin-top: 2px;
}
.headerTopSocialBox {
  position: relative;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerTopSingleSocial {
  height: 24px;
  width: 24px;
  border: 1px solid var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 3px;
  transition-duration: 0.3s;
}
.headerTopSingleSocial:hover {
  border: 1px solid var(--color-primary-light);
}
.headerTopSingleSocial:hover svg {
  color: var(--color-primary-light);
  transition-duration: 0.3s;
}
.headerTopSingleSocial svg {
  color: var(--color-white);
  font-size: 12px;
  transition-duration: 0.3s;
}
.mobileMenu {
  margin-left: 32px;
  height: 40px;
  width: 40px;
  transition-duration: 0.3s;
}
.mobileMenu svg {
  color: var(--color-black);
  transition-duration: 0.3s;
}
.mobileMenu:hover svg {
  color: var(--color-white);
  transition-duration: 0.3s;
}
.slider {
  width: 100%;
  height: calc(100vh - 40px);
}
.sldierImage {
  position: relative;
  height: calc(100vh - 40px);
  width: 100%;
  overflow: hidden;
}
.sldierImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* .sldierImage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 40px);;
  width: 100%;
  z-index: 11;
  background-color: rgb(0 41 41 / 10%);
  pointer-events: none;
} */
.swiperSlider {
  position: relative;
  max-height: calc(100vh - 40px);
}
.sLiderTextBox {
  position: absolute;
  top: 36%;
  left: 0;
  width: 100%;
  height: auto;
}
.sldierText {
  position: relative;
  z-index: 12;
  padding: 24px;
  background: rgb(0 0 0 / 30%);
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(3px);
  border-radius: 30px;
  margin-left: -10px;
}
.sldierText h2 {
  color: var(--color-white);
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: bold;
  margin-bottom: 24px;
  text-shadow: -3px -2px 2px rgb(151 42 46 / 50%), 4px 4px 2px rgb(157 204 204 / 30%);
  font-feature-settings: 'case';
}
.sldierText p {
  color: var(--color-light);
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: normal;
}
.readMore {
  background: var(--color-primary-light);
  color: var(--color-white);
  border: 3px solid var(--color-primary-light);
  width: fit-content;
  font-weight: bold;
  padding: 12px 40px;
  margin-top: 32px;
  border-radius: 10px;
  font-feature-settings: 'case';
  transition-duration: 0.3s;
}
.readMore:hover {
  background: transparent;
  color: var(--color-primary-light);
  border: 3px solid var(--color-primary-light);
  transition-duration: 0.3s;
}
.sliderButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.viewVideo {
  margin-left: 32px;
  background: transparent;
  color: var(--color-white);
  border: 0;
  width: fit-content;
  font-weight: 400;
  margin-top: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition-duration: 0.3s;
}
.viewVideo svg {
  font-size: 2.4rem;
  margin-right: 12px;
}
.viewVideo:hover {
  color: var(--color-primary-light);
  transition-duration: 0.3s;
}
.swiperSlider .swiper-pagination-bullet {
  position: relative;
  height: 20px;
  width: 20px;
  opacity: 0.4;
  margin: 0 6px !important;
  background: var(--color-white);
}
.swiperSlider .swiper-pagination-bullet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: var(--color-primary-light);
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
}
.swiperSlider .swiper-pagination-bullet-active {
  position: relative;
  height: 20px;
  width: 20px;
  opacity: 1;
  background: var(--color-white);
}
.swiperSlider .swiper-pagination-bullet-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: var(--color-primary-light);
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
}
.swiperSlider .swiper-button-prev, .swiperSlider .swiper-button-next {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: var(--color-secondary);
  height: 60px;
  width: 60px;
  background: var(--color-white);
  border-radius: 50%;
  opacity: 0.2;
  transition-duration: 0.3s;
}
.swiperSlider .swiper-button-prev:hover, .swiperSlider .swiper-button-next:hover {
  opacity: 1;
  transition-duration: 0.3s;
}
.swiperSlider .swiper-button-next:after, .swiperSlider .swiper-rtl .swiper-button-prev:after {
  font-size: 24px;
}
.swiperSlider .swiper-button-prev:after, .swiperSlider .swiper-rtl .swiper-button-next:after {
  font-size: 24px;
}
.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--color-white);
}
.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--color-white);
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

/* Common style */
.news {
  padding: 80px 60px;
}
.sectionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sectionTitleLeft p {
  color: var(--color-secondary);
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: bold;
  margin-bottom: 8px;
  font-feature-settings: 'case';
}
.sectionTitleLeft p svg {
  color: var(--color-secondary);
  font-size: 1rem;
  margin-right: 12px;
}
.sectionTitleLeft h3 {
  color: var(--color-primary);
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: bold;
  margin-bottom: 32px;
}
.sectionTitleRight a {
  color: var(--color-primary);
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 15px;
  transition-duration: 0.3s;
}
.sectionTitleRight a:hover {
  color: var(--color-secondary);
  transition-duration: 0.3s;
}
.sectionTitleRight svg {
  font-size: 0.8rem;
  margin-left: 8px;
  transition-duration: 0.3s;
}
.sectionTitleRight a:hover svg {
  transition-duration: 0.3s;
}
.singleNewsBox {
  padding: 24px;
  border-radius: 8px;
  background: var(--color-more-light);
  transition-duration: 0.3s;
}
.singleNewsBox:hover .mainNewsImage img {
  transition-duration: 0.5s;
  transform: scale(1.1);
}
.singleNewsBox:hover {
  transition-duration: 0.5s;
}
.mainNewsImage {
  position: relative;
  height: 220px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 8px;
  transition-duration: 0.3s;
}
.mainNewsImage img {
  height: 100%;
  width: 100%;
  transition-duration: 0.3s;
}
.newsTextBox {
  width: 100%;
  height: auto;
}
.newsTags {
  margin-bottom: 16px;
}
.newsTags span:first-child {
  margin-right: 16px;
}
.newsTags span {
  color: var(--color-dark);
  font-size: 0.8rem;
}
.newsTags span svg {
  color: var(--color-primary);
  font-size: 0.9rem;
  margin-right: 4px;
}
.newsText h2 {
  color: var(--color-primary-dark);
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: bold;
  margin-bottom: 16px;
}
.newsText p {
  color: var(--color-grey);
  font-weight: normal;
  font-size: 0.9rem;
  margin-bottom: 0px;
}
.newsReadMore {
  color: var(--color-primary-dark);
  width: fit-content;
  font-weight: bold;
  font-size: 15px;
  margin-top: 16px;
  text-decoration: underline;
}
.newsSliderBox .swiper {
  padding-bottom: 60px;
}
.newsSliderBox .swiper-pagination {
  bottom: 0;
}
.newsSliderBox .swiper-pagination-bullet {
  position: relative;
  height: 20px;
  width: 20px;
  opacity: 0.4;
  margin: 0 6px !important;
  background: var(--color-primary);
}
.newsSliderBox .swiper-pagination-bullet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: var(--color-white);
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
}
.newsSliderBox .swiper-pagination-bullet-active {
  position: relative;
  height: 20px;
  width: 20px;
  opacity: 1;
  background: var(--color-primary);
}
.newsSliderBox .swiper-pagination-bullet-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: var(--color-primary-light);
  border-radius: 50%;
  margin-top: 2px;
  margin-left: 2px;
}
.newsSliderBox .swiper-button-prev, .newsSliderBox .swiper-button-next {
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: var(--color-secondary);
  height: 60px;
  width: 60px;
  background: var(--color-white);
  border-radius: 50%;
  opacity: 0.2;
  transition-duration: 0.3s;
}
.newsSliderBox .swiper-button-prev:hover, .newsSliderBox .swiper-button-next:hover {
  opacity: 1;
  transition-duration: 0.3s;
}
.newsSliderBox .swiper-button-next:after, .newsSliderBox .swiper-rtl .swiper-button-prev:after {
  font-size: 24px;
}
.newsSliderBox .swiper-button-prev:after, .newsSliderBox .swiper-rtl .swiper-button-next:after {
  font-size: 24px;
}
.videoBox {
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--modal-bg);
  transform: translate(0);
  backdrop-filter: blur(5px);
  transition-duration: 0.3s;
}
.videoBoxInner {
  width: 90%;
  background: var(--color-white);
  height: 90%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.closeVideoBox {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -24px;
  margin-right: -24px;
  cursor: pointer;
}
.closeVideoBox svg {
  font-size: 1.4rem;
}
.events {
  padding: 130px 60px;
  background-image: url(../image/sectionbg2.png);
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.sectionTitleLeftLight p {
  color: var(--color-secondary);
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: bold;
  margin-bottom: 8px;
  font-feature-settings: 'case';
}
.sectionTitleLeftLight p svg {
  color: var(--color-secondary);
  font-size: 1rem;
  margin-right: 12px;
}
.sectionTitleLeftLight h3 {
  color: var(--color-light);
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: bold;
  margin-bottom: 32px;
}
.sectionTitleRightLight a {
  color: var(--color-light);
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 15px;
  transition-duration: 0.3s;
}
.singleEventsBox {
  position: relative;
  padding: 32px 24px;
  border-radius: 24px;
  background: var(--color-white);
  overflow: hidden;
  transition-duration: 0.3s;
}
.singleEventsBox:hover {
  background: var(--color-more-light);
  transition-duration: 0.3s;
}
.eventstTimes {
  position: absolute;
  background: var(--color-primary-light);
  top: 0;
  left: 0;
  padding: 6px 12px;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.eventsText h2 {
  color: var(--color-primary-dark);
  font-size: 1rem;
  line-height: 1.3rem;
  font-weight: 600;
  margin-bottom: 0px;
  padding-left: 60px;
}
.eventstTimes h5 {
  color: var(--color-black);
  font-size: 1.6rem;
  margin-bottom: 5px;
}
.eventstTimes strong {
  color: var(--color-primary);
  font-size: 1.2rem;
}
.eventstTimes p {
  color: var(--color-black);
  margin-bottom: 0px;
  margin-top: 8px;
}
.students {
  padding: 130px 60px;
  background-image: url(../image/sectionbg.png);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.rowCustom {
  display: flex;
  margin: 0 -15px;
  transition: flex-grow 0.3s ease;
}
.colCustom {
  position: relative;
  flex: 1;
  flex-grow: 0;
  transition: flex-grow 0.3s ease;
  min-width: 176px;
  height: 530px;
  margin: 15px;
  padding: 48px 52px 0 50px;
  cursor: pointer;
  overflow: hidden;
  background: var(--color-white);
  border-radius: 10px;
  transition-duration: 0.3s;
}
.colCustom.active {
  flex-grow: 1;
  background: linear-gradient(180deg, var(--color-primary-light) 0%, var(--color-primary) 100%);
  transition-duration: 0.3s;
}
.colCustom:hover {
  background: linear-gradient(180deg, var(--color-primary-light) 0%, var(--color-primary) 100%);
  transition-duration: 0.3s;
}
.colCustom:hover .tpPanelContentTitle h4 {
  color: var(--color-white);
  transition-duration: 0.3s;
}
.tpPanelContent {
  text-align: center;
  transition-duration: 0.3s;
}
.tpPanelContentTitle {
  width: 240px;
  transform: rotate(90deg) translate(145px, 74px);
  transition-duration: 0.3s;
}
.tpPanelContentTitle h4 {
  font-weight: 500;
  font-size: 24px;
  color: var(--color-black);
  margin-bottom: 0;
  font-feature-settings: 'case';
  letter-spacing: 0.1rem;
}
.colCustom.active .tpPanelContentTitle {
  width: 100%;
  transform: rotate(0deg) translate(0px, 32px);
  text-align: center;
  transition-duration: 0.3s;
}
.colCustom.active .tpPanelContentTitle h4 {
  font-weight: 500;
  font-size: 24px;
  color: var(--color-white);
  margin-bottom: 0;
  font-feature-settings: 'case';
  letter-spacing: 0.1rem;
}
.tpPanelContentImage {
  height: 76px;
  width: 76px;
  margin: 0 auto;
}
.tpPanelContentImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.tpPanelContentBody {
  opacity: 0;
  transform: translateY(300px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  height: 0;
  overflow: hidden;
}
.colCustom.active .tpPanelContentBody {
  opacity: 1;
  transform: translateY(0);
  height: auto;
  margin-top: 60px;
}
.tpPanelContentBody h5 {
  margin-bottom: 0px;
  font-size: 1.1rem;
  color: var(--color-white);
  line-height: 1.5rem;
  text-align: center;
  font-weight: 500;
}
.tpPanelContentBody .readMore {
  width: fit-content;
  margin: 0 auto;
  margin-top: 24px;
  background: var(--color-primary);
  color: var(--color-primary-light);
}
.tpPanelContentBody .readMore:hover {
  width: fit-content;
  margin: 0 auto;
  margin-top: 24px;
  background: var(--color-primary);
  border: 3px solid var(--color-white);
  color: var(--color-white);
  transition-duration: 0.3s;
}
.blogDetails {
  margin-bottom: 24px !important;
  border-radius: 10px;
  transition-duration: 0.3s;
}
.blogDetails:hover {
  box-shadow: 3px 3px 10px var(--color-light);
  transition-duration: 0.3s;
}
.blogBg1 {
  background: #FFE6E2;
}
.blogBg2 {
  background: #E3F8FB;
}
.blogBg3 {
  background: #FFF9DF;
}
.blogBg4 {
  background: #F5E6FD;
}
.blogDetailsLeft {
  padding: 30px;
}
.blogDetailsLeftIcon1 {
  background-color: #fcc8c1;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 24px;
}
.blogDetailsLeftIcon1 svg {
  color: #f5523a;
  font-size: 2.4rem;
}
.blogDetailsLeftIcon2 {
  background-color: #c3eef5;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 24px;
}
.blogDetailsLeftIcon2 svg {
  color: #40c7d9;
  font-size: 2.4rem;
}
.blogDetailsLeftIcon3 {
  background-color: #fff2be;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 24px;
}
.blogDetailsLeftIcon3 svg {
  color: #fcd029;
  font-size: 2.4rem;
}
.blogDetailsLeftIcon4 {
  background-color: #e8ccfb;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 24px;
}
.blogDetailsLeftIcon4 svg {
  color: #ba68f6;
  font-size: 2.4rem;
}
.blogDetailsLeftText h4 {
  color: var(--color-black);
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 16px;
}
.blogDetailsLeftText p {
  color: var(--color-black);
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
  margin-bottom: 24px;
  min-height: 134px;
}
.blogDetailsLeftText a {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--color-black);
  transition-duration: 0.3s;
}
.blogDetailsLeftText a:hover {
  color:  var(--color-primary);
  transition-duration: 0.3s;
}
.blogDetailsLeftText a svg {
  font-size: 0.8rem;
  margin-left: 6px;
  transition-duration: 0.3s;
}
.blogDetailsLeftText a:hover svg {
  margin-left: 14px;
  transition-duration: 0.3s;
}
.blogDetailsRight {
  position: relative;
  height: 100%;
  width: 100%;
}
.blogDetailsRightImage {
  position: absolute;
  bottom: 0;
  right: 0;
}
.blogDetailsRightImage img {
  height: auto;
  width: 100%;
}
.dicsover {
  position: relative;
  padding: 130px 0;
  background: var(--color-white);
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.dicsover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: var(--color-primary-dark);
}
.discoverSliderBox {
  position: relative;
}
.discoverSectionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.discoverSectionTitleLeft {
  width: 100%;
  text-align: center;
}
.discoverSectionTitleLeft p {
  color: var(--color-light);
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: bold;
  margin-bottom: 24px;
  font-feature-settings: 'case';
}
.discoverSectionTitleLeft p svg {
  color: var(--color-primary-light);
  font-size: 1.7rem;
  margin-right: 2px;
}
.discoverSectionTitleLeft h3 {
  color: var(--color-white);
  font-size: 2.4rem;
  line-height: 1.8rem;
  font-weight: bold;
  margin-bottom: 70px;
  font-feature-settings: 'case';
}
.discoverSectionTitleRight a {
  color: var(--color-primary);
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 15px;
  transition-duration: 0.3s;
}
.discoverSectionTitleRight a:hover {
  color: var(--color-secondary);
  transition-duration: 0.3s;
}
.discoverSectionTitleRight svg {
  font-size: 0.8rem;
  margin-left: 8px;
  transition-duration: 0.3s;
}
.discoverSectionTitleRight a:hover svg {
  transition-duration: 0.3s;
}
.singleDiscoverBox {
  position: relative;
  padding: 0;
  background: var(--color-white);
  overflow: hidden;
  transition-duration: 0.3s;
  width: 100%;
  height: 500px;
}
.singleDiscoverBox img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.singleDiscoverContent {
  position: absolute;
  content: '';
  bottom: 10%;
  left: 5%;
}
.singleDiscoverContent h2 {
  background-color: var(--color-white);
  padding: 16px 24px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}
.singleDiscoverContent a {
  background-color: var(--color-primary-light);
  padding: 20px 45px;
  color: #fff;
  display: inline-block;
  border-radius: 40px;
  position: relative;
  border: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition-duration: 0.3s;
}
.singleDiscoverContent a:hover {
  background-color: var(--color-primary);
  transition-duration: 0.3s;
}